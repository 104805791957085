 //政治面貌
 const politics = [
    {
        name: '中共党员',
        value: 0
    },
    {
        name: '中共预备党员',
        value: 1
    },
    {
        name: '共青团员',
        value: 2
    },
    {
        name: '民革会员',
        value: 3
    },
    {
        name: '民盟盟员',
        value: 4
    },
    {
        name: '民建会员',
        value: 5
    },
    {
        name: '民进会员',
        value: 6
    },
    {
        name: '农工党党员',
        value: 7
    },
    {
        name: '致公党党员',
        value: 8
    },
    {
        name: '九三学社社员',
        value: 9
    },
    {
        name: '台盟盟员',
        value: 10
    },
    {
        name: '无党派人士',
        value: 11
    },
    {
        name: '群众',
        value: 12
    }
]
//婚姻状态
const marriage = [
    {
         name:'未婚',
         value:0
    },
    {
        name: '已婚',
        value: 1
    },
    {
        name: '离异',
        value: 2
    },
    {
        name: '丧偶',
        value: 3
    }
]
//兵役状况
const soldier = [
    {
        name:'未服兵役',
        value:0
    },
    {
        name: '现役',
        value: 1
    },
    {
        name: '预备役',
        value: 2
    },
    {
        name: '退出现役',
        value: 3
    },
]
//性别
const sex = [
    {
        name:'男',
        value:1
    },
    {
        name: '女',
        value: 0
    }
]
//学历
const edu = [
    {
        name: '文盲',
        value: 0
    },
    {
        name: '小学',
        value: 1
    },
    {
        name: '初中',
        value: 2
    },
    {
        name: '高中',
        value: 3
    },
    {
        name: '中专',
        value: 4
    },
    {
        name: '大专',
        value: 5
    },
    {
        name: '本科',
        value: 6
    },
    {
        name: '研究生',
        value: 7
    },
]
//单位类型
const UnitType = [{
            name: '其他',
            value: 0
        }, 
        {
            name: '党政机关',
            value: 1
        },
        {
            name: '事业单位',
            value: 2
        },
        {
            name: '企业',
            value: 3
        }
    ]

export const options = {
    politics,
    marriage,
    soldier,
    sex,
    edu,
    UnitType,
}