import request from '@/utils/request'

//获取服务单位列表
export function getServiceList(pageid) {
    return request({
        url: '/admin/service/list/' + pageid,
        method: 'get'
    })
}
//获取服务单位详细信息
export function getService(id) {
    return request({
        url: '/admin/service/' + id,
        method: 'get'
    })
}
//新增服务单位
export function StoreService(data) {
    return request({
        url: '/admin/service/',
        method: 'post',
        data: data
    })
}
//修改服务单位信息
export function UpdateService(data) {
    return request({
        url: '/admin/service/' + data.id,
        method: 'PUT',
        data: data
    })
}

//获取中队列表
export function getTeamList() {
    return request({
        url: '/admin/common/team/list/',
        method: 'get'
    })
}


export function DelService(id) {
    return request({
        url: '/admin/service/' + id,
        method: 'DELETE'
    })
}