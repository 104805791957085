<template>
  <el-dialog
    v-model="centerDialogVisible"
    :title="dialogTitle"
    width="30%"
    :close-on-click-modal="false"
  >
    <el-form
      size="mini"
      label-width="auto"
      ref="refForm"
      :disabled="Formdisabled"
      :model="subForm"
      :rules="formRulesMixin"
    >
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="服务单位名称"
              prop="name"
              :rules="formRulesMixin.isNotNull"
            >
              <el-input
                v-model="subForm.name"
                placeholder="请输入服务单位名称"
              ></el-input>
            </el-form-item>
             <el-form-item label="负责人姓名" prop="manager" :rules="formRulesMixin.isNotNull">
                <el-input v-model="subForm.manager" placeholder="请输入负责人姓名"></el-input>
              </el-form-item>
            <el-form-item
              label="负责人联系方式"
              prop="phone"                                                                                                 
              :rules="formRulesMixin.mobile"
            >
              <el-input
                v-model="subForm.phone"
                placeholder="请输入负责人联系方式"
              ></el-input>
            </el-form-item>
               <el-form-item label="单位地址" prop="address" :rules="formRulesMixin.isNotNull">
                 <el-input v-model="subForm.address" placeholder="请输入负责人姓名"></el-input>
               </el-form-item>
                <el-form-item label="单位类型" prop="type_id" :rules="formRulesMixin.ZeroIntCanNull">
                  <el-select placeholder="请选择" v-model="subForm.type_id">
                    <el-option v-for="item in options.UnitType" :key="item.value" :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                 <el-form-item label="所属中队" prop="team_id" :rules="formRulesMixin.ZeroIntCanNull">
                   <el-select placeholder="请选择" v-model="subForm.team_id">
                     <el-option v-for="item in struct" :key="item.id" :label="item.team_name"
                       :value="item.id">
                     </el-option>
                   </el-select>
                   
                 </el-form-item>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-divider></el-divider>
     <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="AddForm" size="mini">提交</el-button>
      </span>
     </template>
  </el-dialog>
</template>

<script setup>
import { defineExpose, getCurrentInstance, ref, defineEmits, reactive,onBeforeMount ,toRefs} from "vue";
import { ElNotification } from "element-plus";
import {getTeamList,StoreService,UpdateService} from "@/api/unit.js"
import {options} from "@/utils/options.js"
let { proxy } = getCurrentInstance();
const emit = defineEmits(["fetchData"]);

//表单内容初始化
let subForm = reactive({
  id:'',
  team_id:'',
  phone:'',
  manager:'',
  type_id:'',
  address:''
})
//let teamList = reactive({});
const state = reactive({
struct: null,
});
//是否显示Dialog
let centerDialogVisible = ref(false);
//初始化Dialog标题
let dialogTitle = ref("");
let Formdisabled = ref(false)
onBeforeMount(()=>{
  getTeamList().then(res=>{
 // teamList = res.data
  state.struct = res.data;
  })
})
let { struct } = toRefs(state);
//编辑框相关
 const showModal = (isEdit, insertdata) => {
   
  if (isEdit) {
    dialogTitle.value = `编辑${insertdata.name}`;
    centerDialogVisible.value = true;
    Formdisabled.value = false;
    console.log(insertdata)
    reshowData(insertdata)

  } else {
    subForm.id = ''
    dialogTitle.value = "添加服务单位";
    centerDialogVisible.value = true;
    Formdisabled.value = false;
    proxy.$nextTick(() => {
      if (proxy.$refs["refForm"] != undefined) {
     proxy.$refs["refForm"].resetFields();
 }
    });
  }
};

//修改
 const reshowData = (row) => {
  proxy.$nextTick(() => {
    proxy.$refs["refForm"].resetFields();
    Object.keys(row).forEach((fItem) => {
      Object.keys(subForm).forEach((sItem) => {
        if (fItem === sItem) {
          subForm[sItem] = row[sItem];
        }else if(row[sItem] == undefined){
          subForm[sItem] = ' ';
        }
      });
    });
  });
};

const refForm = ref(null);
const eleMessage = (res) => {
  let type = "success";
  if (res.code != 200) {
    type = "error";
  } else if (res.code == 200) {
    type = "success";
    centerDialogVisible.value = false;
    emit("fetchData");
  }
  ElNotification({
    title: "提示",
    message: res.msg,
    type: type,
  });
};
//提交表单 校验
let AddForm = () => {
  refForm.value.validate((valid) => {
    if (valid) {
      //校验通过后的操作
      if (subForm.id) {
        //   updateReq()
        UpdateService(subForm).then(res=>{
          eleMessage(res)
        })
      } else {
        //  insertReq()
        StoreService(subForm).then(res=>{
          eleMessage(res)
        })
      }
    } else {
      return false;
    }
  });
};

defineExpose({
  showModal,
  eleMessage,
});
</script>




<style lang="scss" scoped>
.box-card {
  margin-bottom: 5px;
}

.dialog-footer {
  text-align: right;
}

.el-dialog__footer {
  padding: var(--el-dialog-padding-primary);
  padding-top: 10px;
  text-align: right;
  box-sizing: border-box;
}
</style>